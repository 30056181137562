button.teu {
  background-color: #006fba;
  font-weight: bold;
  color: white;
}

.abs-center {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  /* min-height: 100vh; */
}

.form {
  width: 450px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}